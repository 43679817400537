export const commissionImages = [

    {
        url: '/tristane.jpg',
        type: 'halfbody'
    },
    {
        url: '/quartise.jpg',
        type: 'halfbody'
    },
    {
        url: '/myriani.jpg',
        type: 'halfbody'
    },
    {
        url: '/helen-aimee-diana.jpg',
        type: 'halfbody'
    },
    {
        url: '/helen-aimee-ker-tina.jpg',
        type: 'halfbody'
    },
    {
        url: '/helen-aimee-neefia3.jpg',
        type: 'halfbody'
    },
    {
        url: '/helen-aimee-ahri.jpg',
        type: 'halfbody'
    },
    {
        url: '/opal.jpg',
        type: 'waist'
    },
    {
        url: '/kalendar.jpg',
        type: 'waist'
    },
    {
        url: '/mileni3.jpg',
        type: 'waist'
    },
    {
        url: '/kayda.jpg',
        type: 'waist'
    },
    {
        url: '/erelah.jpg',
        type: 'waist'
    },
    {
        url: '/helen-aimee-iris.jpg',
        type: 'waist'
    },
    {
        url: '/helen-aimee-helene.jpg',
        type: 'waist'
    },
    {
        url: '/helen-aimee-sabira.jpg',
        type: 'waist'
    },
    {
        url: '/helen-aimee-allura2.jpg',
        type: 'waist'
    },
    {
        url: '/neefia6.jpg',
        type: 'bust'
    },
    {
        url: '/calyssra2.jpg',
        type: 'bust'
    },
    {
        url: '/elara3.jpg',
        type: 'bust'
    },
    {
        url: '/lunariia.jpg',
        type: 'bust'
    },
    {
        url: '/ti.jpg',
        type: 'bust'
    },
    {
        url: '/lythorean.jpg',
        type: 'bust'
    },
    {
        url: '/zoriana.jpg',
        type: 'sketch'
    },
    {
        url: '/alora2.jpg',
        type: 'sketch'
    },
    {
        url: '/nightborne.jpg',
        type: 'sketch'
    },
    {
        url: '/sindormu.jpg',
        type: 'sketch'
    },
    {
        url: '/naterea.jpg',
        type: 'sketch'
    },
    {
        url: '/saria.jpg',
        type: 'sketch'
    },
    {
        url: '/leyla.jpg',
        type: 'sketch'
    },
    {
        url: '/soreeyah.jpg',
        type: 'sketch'
    },
    {
        url: '/luxidor.jpg',
        type: 'sketch'
    },   
    {
        url: '/elyanthe.jpg',
        type: 'hbsketch'
    },  
    {
        url: '/mariko.jpg',
        type: 'hbsketch'
    }, 
    {
        url: '/rhynali.jpg',
        type: 'hbsketch'
    }, 
    {
        url: '/zhaleh.jpg',
        type: 'hbsketch'
    },
    {
        url: '/iridessa.jpg',
        type: 'hbsketch'
    },
    {
        url: '/zalorii.jpg',
        type: 'hbsketch'
    },  
    {
        url: '/rinh5.jpg',
        type: 'hbsketch'
    },
]