export const adoptableImages = [

    {
        url: '/helen-aimee-mermaid.jpg',
        type: 'forsale'
    },
    {
        url: '/helen-aimee-aalea.jpg',
        type: 'forsale'
    },
    {
        url: '/helen-aimee-domneth.jpg',
        type: 'forsale'
    },
    {
        url: '/helen-aimee-dracthyr.jpg',
        type: 'adopted'
    },
    {
        url: '/helen-aimee-adoptable-01.jpg',
        type: 'adopted'
    },
    {
        url: '/helen-aimee-flameleaf.jpg',
        type: 'adopted'
    },
    {
        url: '/helen-aimee-adoptable-03.jpg',
        type: 'adopted'
    },
    {
        url: '/helen-aimee-ribbon.jpg',
        type: 'adopted'
    }
]